// 中文翻译
const cn = {
  banner: {
    title: '圣贝拉会员权益中心'
  },
  yj: {
    title: '悦己会员 · 甄选礼遇',
    jfls: '自有BELLA SELECTED商城<br>大牌母婴用品免费兑换',
    qyly: '早燕晚胶1+1套装1份/准妈妈手册-趁早<br>圣贝拉门店专属定制礼遇',
    jrlh: '新春专享礼',
    jmsrly: '专属生日祝福卡',
    rzly: '豪华专车接送（奔驰V级）/欢迎鲜花<br>手写欢迎卡/定制储奶袋/妈咪相框<br>STB草本汉方足浴包（10小包）<br>STB草本汉方沐浴包（10小包）',
    lgly: '定制丝感宝宝衣架/定制储奶袋/宝宝日志本<br>离馆当日黑白卡/超能奶爸包',
    hyzxlq: '会员专享礼券1张/季'
  },
  jk: {
    title: '金卡会员 · 甄选礼遇',
    jfls: '自有BELLA SELECTED商城<br>大牌母婴用品免费兑换',
    qyly: '早燕晚胶1+1套装2份/准妈妈手册-趁早<br>圣贝拉门店专属定制礼遇',
    jrlh: '新春专享礼/中秋节专享礼',
    jmsrly: '专属生日祝福卡<br>专属客服语音生日祝福',
    rzly: '豪华专车接送（奔驰V级）/欢迎鲜花<br>手写欢迎卡/定制储奶袋/妈咪相框<br>STB草本汉方足浴包（10小包）<br>STB草本汉方沐浴包（10小包）',
    lgly: '定制丝感宝宝衣架/定制储奶袋/宝宝日志本<br>离馆当日黑白卡<br>超能奶爸包/离馆第三个月品牌定制礼',
    zj1v1fw: '区域护理总监1v1服务（不限次）<br>中医营养博士-钟博士1v1咨询服务（1次）',
    hyzxlq: '会员专享礼券1张/季',
    hyzxhd: '奢侈品牌联合活动'
  },
  bj: {
    title: '铂金会员 · 甄选礼遇',
    jfls: '自有BELLA SELECTED商城<br>大牌母婴用品免费兑换',
    qyly: '早燕晚胶3+3套装1份/准妈妈手册-趁早<br>圣贝拉门店专属定制礼遇',
    jrlh: '新春专享礼/中秋节专享礼<br>母亲节专享礼',
    jmsrly: '专属生日祝福卡<br>专属客服语音生日祝福',
    rzly: '豪华专车接送（奔驰V级）/欢迎鲜花/手写欢迎卡<br>定制储奶袋/妈咪相框/Rotho澡盆架<br>STB草本汉方足浴包（10小包）<br>STB草本汉方沐浴包（10小包）',
    lgly: '定制丝感宝宝衣架/定制储奶袋/宝宝日志本<br>离馆当日黑白卡<br>超能奶爸包/离馆第三个月品牌定制礼',
    zj1v1fw: '区域护理总监1v1服务（不限次）<br>台籍首席护理官-刘博士1v1咨询服务（2次）<br>中医营养博士-钟博士1v1咨询服务（2次）<br>私密产后修复1v1咨询服务（1次）',
    hyzxlq: '会员专享礼券2张/季',
    hyzxhd: '奢侈品牌联合活动/限定亲子美育系列'
  },
  nw: {
    title: '女王蓝钻 · 甄选礼遇',
    jfls: '自有BELLA SELECTED商城<br>大牌母婴用品免费兑换',
    qyly: '早燕晚胶3+3套装1份/准妈妈手册-趁早<br>圣贝拉门店专属定制礼遇',
    jrlh: '新春专享礼/中秋节专享礼<br>母亲节专享礼',
    jmsrly: '专属生日祝福卡<br>专属客服语音生日祝福/精美品牌定制礼1份',
    rzly: '豪华专车接送（奔驰V级）/欢迎鲜花/手写欢迎卡<br>定制储奶袋/妈咪相框/Rotho澡盆架<br>STB草本汉方足浴包（10小包）<br>STB草本汉方沐浴包（10小包）',
    lgly: '定制丝感宝宝衣架/定制储奶袋/宝宝日志本<br>离馆当日黑白卡<br>超能奶爸包/离馆第三个月品牌定制礼',
    zj1v1fw: '区域护理总监1v1服务（不限次）<br>台籍首席护理官-刘博士1v1咨询服务（不限次）<br>中医营养博士-钟博士1v1咨询服务（不限次）<br>私密产后修复1v1咨询服务（1次）',
    hyzxlq: '会员专享礼券2张/季',
    hyzxhd: '奢侈品牌联合活动/限定亲子美育系列<br>明星妈妈私享会/新店开业派对<br>品牌晚宴'
  },
  jfls: '积分礼赏',
  qyly: '签约礼遇',
  jrlh: '节日礼',
  jmsrly: '精美生日礼遇',
  rzly: '入住礼遇',
  lgly: '离馆礼遇',
  zj1v1fw: '专家1V1服务',
  hyzxlq: '会员专享礼券',
  hyzxhd: '会员专享活动'
}
// 繁体翻译
const tw = {
  banner: {
    title: '聖貝拉會員權益中心'
  },
  yj: {
    title: '悅己會員 · 甄選禮遇',
    jfls: '自有BELLA SELECTED商城<br>大牌母嬰用品免費兌換',
    qyly: '早燕晚膠1+1套裝1份/准媽媽手册-趁早<br>聖貝拉門店專屬定制禮遇',
    jrlh: '新春專享禮',
    jmsrly: '專屬生日祝福卡',
    rzly: '豪華專車接送（賓士V級）/歡迎鮮花<br>手寫歡迎卡/定制儲奶袋/媽咪相框<br>STB草本漢方腳底按摩包（10小包）<br>STB草本漢方沐浴包（10小包）',
    lgly: '定制絲感寶寶衣架/定制儲奶袋/寶寶日誌本<br>離館當日黑白卡/超能奶爸包',
    hyzxlq: '會員專享禮券1張/季'
  },
  jk: {
    title: '金卡會員 · 甄選禮遇',
    jfls: '自有BELLA SELECTED商城<br>大牌母嬰用品免費兌換',
    qyly: '早燕晚膠1+1套裝2份/准媽媽手册-趁早<br>聖貝拉門店專屬定制禮遇',
    jrlh: '新春專享禮/中秋節專享禮',
    jmsrly: '專屬生日祝福卡<br>專屬客服語音生日祝福',
    rzly: '豪華專車接送（賓士V級）/歡迎鮮花<br>手寫歡迎卡/定制儲奶袋/媽咪相框<br>STB草本漢方腳底按摩包（10小包）<br>STB草本漢方沐浴包（10小包）',
    lgly: '定制絲感寶寶衣架/定制儲奶袋/寶寶日誌本<br>離館當日黑白卡<br>超能奶爸包/離館第三個月品牌定制禮',
    zj1v1fw: '區域護理總監1v1服務（不限次）<br>中醫營養博士-鐘博士1v1諮詢服務（1次）',
    hyzxlq: '會員專享禮券1張/季',
    hyzxhd: '奢侈品牌聯合活動'
  },
  bj: {
    title: '鉑金會員 · 甄選禮遇',
    jfls: '自有BELLA SELECTED商城<br>大牌母嬰用品免費兌換',
    qyly: '早燕晚膠3+3套裝1份/准媽媽手册-趁早<br>聖貝拉門店專屬定制禮遇',
    jrlh: '新春專享禮/中秋節專享禮<br>母親節專享禮',
    jmsrly: '專屬生日祝福卡<br>專屬客服語音生日祝福',
    rzly: '豪華專車接送（賓士V級）/歡迎鮮花/手寫歡迎卡<br>定制儲奶袋/媽咪相框/Rotho澡盆架<br>STB草本漢方腳底按摩包（10小包）<br>STB草本漢方沐浴包（10小包）',
    lgly: '定制絲感寶寶衣架/定制儲奶袋/寶寶日誌本<br>離館當日黑白卡<br>超能奶爸包/離館第三個月品牌定制禮',
    zj1v1fw: '區域護理總監1v1服務（不限次）<br>臺籍首席護理官-劉博士1v1諮詢服務（2次）<br>中醫營養博士-鐘博士1v1諮詢服務（2次）<br>私密產後修復1v1諮詢服務（1次）',
    hyzxlq: '會員專享禮券2張/季',
    hyzxhd: '奢侈品牌聯合活動/限定親子美育系列'
  },
  nw: {
    title: '女王藍鑽 · 甄選禮遇',
    jfls: '自有BELLA SELECTED商城<br>大牌母嬰用品免費兌換',
    qyly: '早燕晚膠3+3套裝1份/准媽媽手册-趁早<br>聖貝拉門店專屬定制禮遇',
    jrlh: '新春專享禮/中秋節專享禮<br>母親節專享禮',
    jmsrly: '專屬生日祝福卡<br>專屬客服語音生日祝福/精美品牌定制禮1份',
    rzly: '豪華專車接送（賓士V級）/歡迎鮮花/手寫歡迎卡<br>定制儲奶袋/媽咪相框/Rotho澡盆架<br>STB草本漢方腳底按摩包（10小包）<br>STB草本漢方沐浴包（10小包）',
    lgly: '定制絲感寶寶衣架/定制儲奶袋/寶寶日誌本<br>離館當日黑白卡<br>超能奶爸包/離館第三個月品牌定制禮',
    zj1v1fw: '區域護理總監1v1服務（不限次）<br>臺籍首席護理官-劉博士1v1諮詢服務（不限次）<br>中醫營養博士-鐘博士1v1諮詢服務（不限次）<br>私密產後修復1v1諮詢服務（1次）',
    hyzxlq: '會員專享禮券2張/季',
    hyzxhd: '奢侈品牌聯合活動/限定親子美育系列<br>藝員媽媽私享會/新店開業派對<br>品牌晚宴'
  },
  jfls: '積分禮賞',
  qyly: '簽約禮遇',
  jrlh: '節日禮',
  jmsrly: '精美生日禮遇',
  rzly: '入住禮遇',
  lgly: '離館禮遇',
  zj1v1fw: '專家1V1服務',
  hyzxlq: '會員專享禮券',
  hyzxhd: '會員專享活動'
}
// 英文翻译
const en = {
  banner: {
    title: '' // 圣贝拉会员权益中心
  },
  yj: {
    title: 'Executive · Card',
    jfls: 'Redeem your points for premium maternity and baby products<br>available on our SAINT BELLA SELECTED online store',
    qyly: 'The Cubilose and Fish Maw 1+1 Gift Set*1 /New Mom\'s Handbook<br>SAINT BELLA Designed Amenity',
    jrlh: 'Chinese New Year Gift',
    jmsrly: 'Exclusive Birthday Card',
    rzly: 'Limousine Service（Mercedes-Benz V Class）/Welcome Bouquet/Welcome Card<br>SAINT BELLA Milk Storage Bags/Mummy Photo Frame<br>STB Herbal Formula Foot Care (10 pieces) <br>STB  Herbal Formula Body Care (10 pieces）',
    lgly: 'Designed Baby Silk Hanger/SAINT BELLA Milk Storage Bags/Baby Growth Journal<br>Black and White Cards for Newborn(given upon Check-out)/Super Daddy Bag',
    hyzxlq: '1 per Season'
  },
  jk: {
    title: 'Golden · Card',
    jfls: 'Redeem your points for premium maternity and baby products<br>available on our SAINT BELLA SELECTED online store',
    qyly: 'The Cubilose and Fish Maw 1+1 Gift Set*2/New Mom\'s Handbook<br>SAINT BELLA Designed Amenity',
    jrlh: 'Chinese New Year Gift/Mid-autumn Festival Gift',
    jmsrly: 'Exclusive Birthday Card<br>Dedicated Birthday Greetings from Customer Center',
    rzly: 'Limousine Service（Mercedes-Benz V Class）/Welcome Bouquet/Welcome Card<br>SAINT BELLA Milk Storage Bags/Mummy Photo Frame<br>STB Herbal Formula Foot Care (10 pieces) <br>STB  Herbal Formula Body Care (10 pieces）',
    lgly: 'Designed Baby Silk Hanger/Designed Milk Storage Bags/Baby Growth Journal<br>Black and White Cards for Newborn(given upon Check-out)<br>Super Daddy Bag/SAINT BELLA Desinged Gift after 3 Months Departure',
    zj1v1fw: 'Regional Supervisor of Nursing 1 on 1 Service Unlimited<br>Doctor of Chinese Medicine and Nutrition - Doctor Zhong 1 on 1 service 1 time',
    hyzxlq: '1 per Season',
    hyzxhd: 'Co-branding Event with Luxury Brands'
  },
  bj: {
    title: 'Platinum · Card',
    jfls: 'Redeem your points for premium maternity and baby products<br>available on our SAINT BELLA SELECTED online store',
    qyly: 'The Cubilose and Fish Maw 3+3 Gift Set*1/New Mom\'s Handbook<br>SAINT BELLA Designed Amenity',
    jrlh: 'Chinese New Year Gift/Mid-autumn Festival Gift<br>Mother\'s Day Gift',
    jmsrly: 'Exclusive Birthday Card<br>Dedicated Birthday Greetings from Customer Center',
    rzly: 'Limousine Service（Mercedes-Benz V Class）<br>Welcome Bouquet/Welcome Card<br>SAINT BELLA Milk Storage Bags<br>Mummy Photo Frame/Rotho Bath Rack<br>STB Herbal Formula Foot Care (10 pieces) <br>STB  Herbal Formula Body Care (10 pieces）',
    lgly: 'Designed Baby Silk Hanger/Designed Milk Storage Bags/Baby Growth Journal<br>Black and White Cards for Newborn(given upon Check-out)<br>Super Daddy Bag/SAINT BELLA Desinged Gift after 3 Months Departure',
    zj1v1fw: 'Regional Supervisor of Nursing 1 on 1 Service Unlimited<br>Taiwan Chef Nursing Officer-Doctor Liu 1 on 1 Consulting 2 times<br>Doctor of Chinese Medicine and Nutrition - Doctor Zhong 1 on 1 service 2 times<br>Postpartum Private Repair 1 on 1 Consulting 1 time',
    hyzxlq: '2 per Season',
    hyzxhd: 'Co-branding Event with Luxury Brands/Selected Art Education Series for Family'
  },
  nw: {
    title: 'Blue Diamond · Card',
    jfls: 'Redeem your points for premium maternity and baby products<br>available on our SAINT BELLA SELECTED online store',
    qyly: 'The Cubilose and Fish Maw 3+3 Gift Set*2/New Mom\'s Handbook<br>SAINT BELLA Designed Amenity',
    jrlh: 'Chinese New Year Gift/Mid-autumn Festival Gift<br>Mother\'s Day Gift',
    jmsrly: 'Exclusive Birthday Card<br>Dedicated Birthday Greetings from Customer Center/Dedicated Birthday Greetings from Customer Center',
    rzly: 'Limousine Service（Mercedes-Benz V Class）<br>Welcome Bouquet/Welcome Card<br>SAINT BELLA Milk Storage Bags<br>Mummy Photo Frame/Rotho Bath Rack<br>STB Herbal Formula Foot Care (10 pieces) <br>STB  Herbal Formula Body Care (10 pieces）',
    lgly: 'Designed Baby Silk Hanger/Designed Milk Storage Bags/Baby Growth Journal<br>Black and White Cards for Newborn(given upon Check-out)<br>Super Daddy Bag/SAINT BELLA Desinged Gift after 3 Months Departure',
    zj1v1fw: 'Regional Supervisor of Nursing 1 on 1 Service Unlimited<br>Taiwan Chef Nursing Officer-Doctor Liu 1 on 1 Consulting Unlimited<br>Doctor of Chinese Medicine and Nutrition - Doctor Zhong 1 on 1 service Unlimited<br>Postpartum Private Repair 1 on 1 Consulting 1 time',
    hyzxlq: '2 per Season',
    hyzxhd: 'Co-branding Event with Luxury Brands/Selected Art Education Series for Family<br>Celebraty Mom Sharing Talk/Openning Ceremony<br>SAINT BELLA Gala Dinner'
  },
  jfls: 'Discover Rewards from Point Collection',
  qyly: 'Signing Amenity',
  jrlh: 'Fesitval Gift',
  jmsrly: 'Birthday Amenity',
  rzly: 'Check-in Amenity',
  lgly: 'Check-out Amenity',
  zj1v1fw: 'Expert 1V1',
  hyzxlq: 'Menbers-only Gift Voucher',
  hyzxhd: 'Members-only Events'
}
export { cn, tw, en }
